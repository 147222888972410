import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import posthog from "posthog-js";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  useUser,
  useClerk,
} from "@clerk/clerk-react";
import { useDispatch, useSelector } from "react-redux";
import { isTellenUser, classNames } from "./utils";
import {
  Notifications,
  NotificationsProvider,
} from "./utils/notifications/Notifications";
import { customizationsSelector, logUser } from "./redux/reducers/app.reducer";
import Navigation from "./navigation";
import Router from "./router";
import "./App.css";
import "./assets/main.css";
import SignInModal from "./utils/SignInModal";
import SignIn from "./SignIn";

// const teams = [
//   { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
//   { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
//   { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
// ]
const userNavigation = [{ name: "Profile", to: "/profile" }];

export default function App() {
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { environmentLabelOptions, logo } = useSelector(customizationsSelector);

  const [signInModalOpen, setSignInModalOpen] = useState(false);

  const location = useLocation();

  const { isSignedIn, user, isLoaded } = useUser();
  const [hasAdminFrontendAccess, setHasAdminFrontendAccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded) {
      if (isSignedIn) {
        console.log("User object");
        console.log(user);
        dispatch(logUser({ details: user }));
        posthog.identify(user.id, {
          email: user.primaryEmailAddress,
          name: user.fullName,
        });
      } else {
        posthog.reset();
        navigate("/sign_in");
      }
    }

    const hostname = window.location.hostname;
    if (
      hostname.endsWith(".advantagepartners.ai") ||
      hostname.endsWith(".johansonllp.com") ||
      isTellenUser(user)
    ) {
      setHasAdminFrontendAccess(true);
    }
  }, [isSignedIn, user, isLoaded]);

  const { signOut } = useClerk();

  const EnvironmentLabel = () => {
    return (
      <SignedIn>
        {isTellenUser(user) && (
          <div className="flex justify-center items-center">
            <div
              className={`${environmentLabelOptions.bgColor} text-white font-bold py-2 px-4 rounded-full`}
            >
              {environmentLabelOptions.label}
            </div>
          </div>
        )}
      </SignedIn>
    );
  };

  if (location.pathname === "/sign_in") {
    return <SignIn />;
  }

  return (
    <NotificationsProvider>
      {isSignedIn && (
        <div className={`h-screen`}>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-customBgColor/80" />
              </Transition.Child>

              <div className="fixed inset-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <button
                          type="button"
                          className="-m-2.5 p-2.5"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-customBgColor px-6 pb-4 ring-1 ring-white/10">
                      <div className="flex h-16 shrink-0 items-center">
                        {/* <h1 className="text-white text-6xl tracking-wide font-extrabold"> */}
                        <img
                          src={`https://tellencl.blob.core.windows.net/main/${logo}`}
                          alt="Logo"
                        />
                        {/* </h1> */}
                      </div>
                      <Navigation />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-customBgColor pb-4">
              <div className="flex flex-col h-16 shrink-0 items-center m-4 p-4">
                <img
                  src={`https://tellencl.blob.core.windows.net/main/${logo}`}
                  alt="Logo"
                />
                {/* <h2 className="text-white font-bold text-3xl">APP</h2> */}
              </div>
              <br />
              <Navigation />
            </div>
          </div>

          <div className="lg:pl-72">
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Separator */}
              <div
                className="h-6 w-px bg-customBgColor/10 lg:hidden"
                aria-hidden="true"
              />

              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <div className="relative flex flex-1">
                  <div className="flex justify-center items-center">
                    <EnvironmentLabel />
                  </div>
                </div>
                {/* <form className="relative flex flex-1" action="#" method="GET"> */}
                {/* <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                /> */}
                {/* </form> */}
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {/* Separator */}
                  <div
                    className="hidden lg:block lg:h-6 lg:w-px lg:bg-customBgColor/10"
                    aria-hidden="true"
                  />

                  {/* Profile dropdown */}
                  <SignedIn>
                    {/* <UserButton /> */}
                    <Menu
                      as="div"
                      className="relative"
                      data-testid="user-header-actions-btn"
                    >
                      <Menu.Button className="-m-1.5 flex items-center p-1.5">
                        <span className="sr-only">Open user menu</span>
                        {user ? (
                          <img
                            className="h-8 w-8 rounded-full bg-gray-50"
                            src={user["imageUrl"]}
                            alt=""
                          />
                        ) : null}
                        <span className="hidden lg:flex lg:items-center">
                          <span
                            className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                            aria-hidden="true"
                          >
                            {user && user["firstName"] !== ""
                              ? user["firstName"]
                              : ""}
                          </span>
                          <ChevronDownIcon
                            className="ml-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link
                                  to={item.to}
                                  className={classNames(
                                    active ? "bg-gray-50" : "",
                                    "block px-3 py-1 text-sm leading-6 text-gray-900 no-underline"
                                  )}
                                >
                                  {item.name}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                          {hasAdminFrontendAccess && (
                            <>
                              <div className="border-t border-gray-200"></div>
                              <Menu.Item key="user_list">
                                {({ active }) => (
                                  <Link
                                    to="/admin_users"
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block px-3 py-1 text-sm leading-6 text-gray-900 no-underline"
                                    )}
                                  >
                                    Logs
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item key="feedback">
                                {({ active }) => (
                                  <Link
                                    to="/admin_feedback"
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block px-3 py-1 text-sm leading-6 text-gray-900 no-underline"
                                    )}
                                  >
                                    Feedback
                                  </Link>
                                )}
                              </Menu.Item>
                              {/* <Menu.Item key="activity_log">
                              {({ active }) => (
                                <Link
                                  to="/admin_activity_log"
                                  className={classNames(
                                    active ? "bg-gray-50" : "",
                                    "block px-3 py-1 text-sm leading-6 text-gray-900"
                                  )}
                                >
                                  Activity Log
                                </Link>
                              )}
                            </Menu.Item> */}
                              <div className="border-t border-gray-200"></div>
                            </>
                          )}
                          <Menu.Item key="changelog">
                            {({ active }) => (
                              <Link
                                to="/changelog"
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900 no-underline"
                                )}
                              >
                                Changelog
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item key="sign_out">
                            {({ active }) => (
                              <Link
                                onClick={() => signOut()}
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900 no-underline"
                                )}
                              >
                                Sign Out
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </SignedIn>
                  <SignedOut>
                    {/* <SignInModal
                    open={signInModalOpen}
                    setOpen={setSignInModalOpen}
                  />
                  <a
                    onClick={() => setSignInModalOpen(true)}
                    className="text-black no-underline cursor-pointer"
                  >
                    Sign In
                  </a> */}
                    <Link to="/sign_in" className="text-black no-underline">
                      Sign In
                    </Link>
                    {/* <SignInButton /> */}
                  </SignedOut>
                </div>
              </div>
            </div>

            <main className="py-10 h-[calc(100vh-4rem)]">
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="content">
                  <section className="text-gray-500">
                    <Router />
                  </section>
                </div>
              </div>
            </main>
          </div>
          <Notifications />
        </div>
      )}
    </NotificationsProvider>
  );
}
