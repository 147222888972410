import { Link } from "react-router-dom";
import React from "react";
import {
  ArrowDownTrayIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import FootnoteAppIcon from "../utils/FootnoteAppIcon";
import ChatbotIcon from "../utils/ChatbotIcon";
import classnames from "classnames";

const getIcon = (slug) => {
  const footnoteApps = [
    "footnote_ai0",
    "10k-footnote-search",
    "generate_a_footnote",
    "internal_consistency_check",
    "roll_forward_financial_statements",
  ];

  if (footnoteApps.includes(slug)) return FootnoteAppIcon;
  if (slug === "general_data_chat") return ChatbotIcon;
  return DocumentMagnifyingGlassIcon;
};

const AppCardLong = ({ card, url, cta_text, slug }) => {
  const Icon = getIcon(slug);
  const isFootnoteApp = Icon === FootnoteAppIcon;

  return (
    <div
      data-testid="card-item"
      className="group p-6 my-4 w-full border-lightgray border-1 rounded-lg transition-colors duration-300 flex flex-col space-y-3 text-customDarkGray"
    >
      <div className="flex items-center space-x-4">
        <div className="flex w-12 h-12 p-3 bg-blue-50 rounded">
          <Icon
            className={classnames("h-6 w-6", {
              "text-blue-500": !isFootnoteApp,
              "text-customHighlightColor": isFootnoteApp,
            })}
          />
        </div>
        <div
          data-testid="card-item-name"
          className="flex-1 text-xl font-medium break-words text-gray-700"
        >
          {card.name}
        </div>
      </div>
      <div className="w-full text-base py-2 text-gray-500">
        {card.description}
      </div>
      <div className="flex flex-col items-start w-full flex-grow text-sm border-b border-lightgray">
        <p>{/* Add published date or other metadata here */}</p>
      </div>
      <Link
        data-testid="card-item-go-btn"
        className="flex items-center py-3 px-6 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 w-full"
        to={url}
      >
        <ArrowDownTrayIcon className="w-5 h-5 mr-2" /> {cta_text}
      </Link>
    </div>
  );
};

export default AppCardLong;