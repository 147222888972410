import { useState, useEffect, useCallback } from "react";
import { getRequest } from "../utils/httpUtils";

/**
 * Custom hook to fetch companies and footnote titles from the server.
 *
 * @returns {{
 *   companies: object[] | null,
 *   footnoteTitles: object[] | null,
 *   isLoading: boolean,
 *   error: string | null,
 *   refetch: function,
 * }} Hook state and functions to handle companies and footnote titles.
 */
const useFetchFootnoteFiltersData = () => {
  const [companies, setCompanies] = useState(null);
  const [footnoteTitles, setFootnoteTitles] = useState(null);
  const [years, setYears] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use useCallback to memoize the fetchFiltersData function
  const fetchFiltersData = useCallback(async () => {
    setIsLoading(true);
    setError(null); // Reset error state

    try {
      const response = await getRequest("/api/footnotes/get_filters_data");
      if (response?.data) {
        setCompanies(response.data?.companies || []);
        setFootnoteTitles(response.data?.footnote_titles || []);
        setYears(response.data?.years || []);
        setIndustries(response.data?.industries || []);
      }
    } catch (err) {
      setError("Failed to load filters data.");
      console.error("Error fetching filters data:", err);
    } finally {
      setIsLoading(false);
    }
  }, []); 

  useEffect(() => {
    fetchFiltersData();
  }, []); // No dependencies, so this runs once when the component mounts

  return {
    companies,
    footnoteTitles,
    industries,
    years,
    isLoading,
    error,
    refetch: fetchFiltersData, // Expose the fetchFiltersData function as refetch
  };
};

export default useFetchFootnoteFiltersData;
