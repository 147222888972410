import React, { useMemo, useCallback } from "react";
import { handleCSVDownload, handleXlsxDownload } from "./utils";
import DownloadDropdown from "./DownloadDropdown";

const TableDetails = ({
  tableDetails,
  setHighlightAreas,
  fileID,
  jumpToPage,
  highlights = [], // [[row, column, color], ...]
}) => {
  const parsedTableDetails = useMemo(() => {
    if (!tableDetails) return [];
    return tableDetails;
  }, [tableDetails]);

  // Find the maximum column index
  const maxCol = useMemo(() => {
    return Math.max(...parsedTableDetails.map((item) => item.col));
  }, [parsedTableDetails]);

  const groupByRows = (data) => {
    // Create a complete grid with empty cells where needed
    const groupedData = data.reduce((acc, item) => {
      const rowKey = item.row.toString();
      if (!acc[rowKey]) {
        // Initialize the row with empty cells for all possible columns
        acc[rowKey] = Array(maxCol + 1)
          .fill(null)
          .map((_, colIndex) => ({
            col: colIndex,
            row: parseInt(rowKey),
            content: "",
            kind: "content",
            page_width: item.page_width,
            page_height: item.page_height,
            page_number: item.page_number,
            top: item.top,
            left: item.left,
            width: item.width,
            height: item.height,
          }));
      }
      // Replace the empty cell with the actual cell
      acc[rowKey][item.col] = item;
      return acc;
    }, {});

    Object.values(groupedData).forEach((array) => {
      array.sort((a, b) => a.col - b.col);
    });
    return groupedData;
  };

  const structuredData = useMemo(
    () => groupByRows(parsedTableDetails),
    [parsedTableDetails, maxCol]
  );

  const highlightMap = useMemo(() => {
    const map = new Map();
    highlights.forEach(([row, col, color]) => {
      const key = `${row}-${col}`;
      map.set(key, color);
      console.log(`Setting highlight for row ${row}, col ${col}: ${color}`);
    });
    return map;
  }, [highlights]);

  const getCellHighlight = (row, col) => {
    const key = `${row}-${col}`;
    const color = highlightMap.get(key);
    console.log(`Checking highlight for row ${row}, col ${col}: ${color}`);
    return color;
  };

  const handleCSVDownloadCallback = useCallback(() => {
    handleCSVDownload(transformTableForCSVDownload(parsedTableDetails));
  }, [parsedTableDetails]);

  const handleXlsxDownloadCallback = useCallback(() => {
    handleXlsxDownload(transformTableForCSVDownload(parsedTableDetails));
  }, [parsedTableDetails]);

  const transformTableForCSVDownload = (inputData) => {
    const headers = inputData
      .filter((item) => item.row === 0)
      .sort((a, b) => a.col - b.col)
      .map((item) => item.content);

    let rowData = {};

    inputData.forEach((item) => {
      if (item.row > 0) {
        if (!rowData[item.row]) {
          rowData[item.row] = new Array(headers.length).fill("");
        }
        rowData[item.row][item.col] = item.content;
      }
    });

    return Object.values(rowData).map((row) => {
      return row.reduce((obj, content, index) => {
        obj[headers[index]] = content;
        return obj;
      }, {});
    });
  };

  const setHighlightAreasInternal = (cell) => {
    if (!setHighlightAreas || !jumpToPage) return;

    const pageNumber = cell.page_number - 1;
    setHighlightAreas([
      {
        file_id: fileID,
        pageIndex: pageNumber,
        height: (cell.height / cell.page_height) * 100,
        width: (cell.width / cell.page_width) * 100,
        left: (cell.left / cell.page_width) * 100,
        top: (cell.top / cell.page_height) * 100,
      },
    ]);
    jumpToPage(pageNumber);
  };

  const highlightingEnabled = Boolean(setHighlightAreas && jumpToPage);

  // Debug logging
  console.log("Structured Data:", structuredData);
  console.log("Highlight Map:", Array.from(highlightMap.entries()));

  return (
    Math.max(...tableDetails.map((item) => item.row)) > 0 && (
      <>
        <table className="table-auto w-full">
          <thead>
            <tr className="border-b border-gray-300">
              {Array(maxCol + 1)
                .fill(null)
                .map((_, colIndex) => {
                  const cell = structuredData["0"]?.[colIndex] || {
                    content: "",
                    row: 0,
                    col: colIndex,
                  };
                  const highlightColor = getCellHighlight(0, colIndex);
                  return (
                    <th
                      key={colIndex}
                      className={`min-w-[150px] p-2 ${
                        highlightingEnabled
                          ? "cursor-pointer hover:bg-gray-100"
                          : ""
                      }`}
                      style={{
                        backgroundColor: highlightColor || "",
                        transition: "background-color 0.2s",
                      }}
                      onMouseEnter={() =>
                        cell && setHighlightAreasInternal(cell)
                      }
                    >
                      {cell.content}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {Object.entries(structuredData).map(([rowKey, rowData]) => {
              if (rowKey === "0") return null;

              return (
                <tr key={rowKey} className="border-b">
                  {Array(maxCol + 1)
                    .fill(null)
                    .map((_, colIndex) => {
                      const cell = rowData[colIndex];
                      const highlightColor = getCellHighlight(
                        parseInt(rowKey),
                        colIndex
                      );
                      return (
                        <td
                          key={colIndex}
                          className={`text-sm py-2 px-2 border-gray-300 ${
                            highlightingEnabled
                              ? "cursor-pointer hover:bg-gray-100"
                              : ""
                          }`}
                          style={{
                            backgroundColor: highlightColor || "",
                            transition: "background-color 0.2s",
                          }}
                          onMouseEnter={() =>
                            cell && setHighlightAreasInternal(cell)
                          }
                        >
                          {cell?.content || ""}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <DownloadDropdown
          handleCSVDownloadCallback={handleCSVDownloadCallback}
          handleXlsxDownloadCallback={handleXlsxDownloadCallback}
        />
        <br />
      </>
    )
  );
};

export default TableDetails;
