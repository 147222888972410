import { useState, useEffect } from "react";
import { postRequest } from "../../utils/httpUtils";
import TableDetails from "../../utils/TableDetails";
import { Viewer } from "../../utils";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";

export default function Results({ files, appInstanceID }) {
  const [tables, setTables] = useState({});
  useEffect(() => {
    console.log("Checking consistency...");
    const getTables = async () => {
      try {
        const response = await postRequest(
          "/api/footnote_ai/consistency_check",
          {
            files: files,
            app_instance_id: appInstanceID,
          }
        );
        console.log(response.data.data);
        setTables(response.data.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    };

    getTables();
  }, [files]);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const jumpToPage = pageNavigationPluginInstance.jumpToPage;

  const processComparisons = (comparisons) => {
    const cellsToHighlight = {
      0: [], // For first table
      1: [], // For second table
    };

    if (comparisons) {
      comparisons.forEach((item) => {
        console.log("Processing comparison:", {
          value1: item.value1,
          value2: item.value2,
          is_equal: item.is_equal,
          positions: item.position,
        });

        // Explicitly check is_equal and assign colors
        let color;
        if (item.is_equal === true) {
          color = "#90EE90"; // Green for matching values
        } else if (item.is_equal === false) {
          color = "#FF7F7F"; // Red for different values
        } else {
          console.warn("Unexpected is_equal value:", item.is_equal);
          color = "#CCCCCC"; // Grey for unexpected cases
        }

        // First table position
        const [table1Row, table1Col] = item.position[0];
        cellsToHighlight[0].push([table1Row, table1Col, color]);

        // Second table position
        const [table2Row, table2Col] = item.position[1];
        cellsToHighlight[1].push([table2Row, table2Col, color]);

        // Debug logging
        console.log(`Setting color ${color} for positions:`, {
          table1: [table1Row, table1Col],
          table2: [table2Row, table2Col],
        });
      });
    }

    console.log("Final highlight maps:", cellsToHighlight);

    return cellsToHighlight;
  };

  return (
    <div className="p-4">
      {Object.entries(tables).map(([footnoteTitle, files], i) => {
        const comparisons =
          Object.values(tables)[i]?.["comparisons"]?.["results"] || null;
        const highlightsByTable = processComparisons(comparisons);

        const cellsToHighlight = {};

        return (
          <div key={footnoteTitle} className="mb-8">
            <h1 className="text-xxl font-bold mb-4 text-center">
              {footnoteTitle}
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {Object.entries(files).map(
                ([fileArrayString, arrayValues], tableIndex) => {
                  if (fileArrayString === "comparisons") return null;

                  const [fileName, fileID] = JSON.parse(fileArrayString);

                  return (
                    <div
                      key={fileArrayString}
                      className="border rounded-lg p-4"
                    >
                      <h3 className="text-md mb-4 text-center font-semibold">
                        {fileName}
                      </h3>

                      {/* Container for Viewer */}
                      <div className="mb-6">
                        <div className=" border rounded overflow-hidden">
                          <Viewer
                            fileUrl={`/api/files/${fileID}.pdf`}
                            plugins={[pageNavigationPluginInstance]}
                            onDocumentLoad={() => {
                              if (arrayValues && arrayValues.length > 0) {
                                const parsedData = JSON.parse(arrayValues[0]);
                                if (parsedData.cells && parsedData.cells[0]) {
                                  jumpToPage(
                                    parsedData.cells[0].page_number - 1
                                  );
                                }
                              }
                            }}
                          />
                        </div>
                      </div>

                      {/* Separate container for TableDetails */}
                      {arrayValues && arrayValues.length > 0 && (
                        <div className="border rounded p-4 bg-white">
                          <TableDetails
                            tableDetails={JSON.parse(arrayValues[0]).cells}
                            key={`${fileName}-table`}
                            highlights={highlightsByTable[tableIndex]}
                          />
                          {/* <pre>
                            {comparisons &&
                              JSON.stringify(comparisons, null, 2)}
                          </pre>
                          FFFF
                          <pre>
                            {JSON.stringify(
                              Object.values(cellsToHighlight)[i],
                              null,
                              2
                            )}
                          </pre> */}
                        </div>
                      )}
                    </div>
                  );
                }
              )}
            </div>
            <hr className="my-8" />
          </div>
        );
      })}
    </div>
  );
}
