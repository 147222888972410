import { SignIn } from '@clerk/clerk-react';
import tellenLogo from './images/tellen_logo.png'
import { useSelector } from 'react-redux';
import { customizationsSelector } from './redux/reducers/app.reducer';

export default function SignInPage() {
  const { logo: companyLogo, name } = useSelector(customizationsSelector);

  return (
    <div className="flex h-screen w-full text-white justify-center items-center">
      <div className="absolute top-0 left-0 w-full h-full  bg-customBgColor bg-login bg-contain" />
      {/* Left Panel */}
      <div className="relative flex flex-col items-center justify-center w-178 h-5/6 bg-opacity-90 rounded-3xl" id="signin-background">
        <div className="w-full h-full flex items-center justify-center" style={{
          backdropFilter: "brightness(0.65) saturate(1.4)"
        }}>
        <div className='py-10 px-20 flex flex-col'>
          <div className="text-center w-122 flex flex-col items-center justify-around">
            {/* <h1 className="text-4xl font-bold text-white capitalize">{name}</h1> */}
            <img src={`https://tellencl.blob.core.windows.net/main/${companyLogo}`} alt="Company Logo" className="h-12 m-4" />
            <p className="text-lg leading-tight text-gray-200">
              Welcome to the <span className="font-semibold">{name}</span> AI portal, where <span className="font-semibold">{name}</span> employees access custom AI solutions to boost productivity and deliver fast, precise insights.
            </p>
          </div>
          <div className='absolute left-0 bottom-14 w-full flex flex-col items-center'>

            <div className="mt-8 text-sm text-gray-400 flex items-center space-x-1">
              <span>Powered by</span>
              <img src={tellenLogo} alt="Tellen Logo" className="h-4" />
            </div>
          </div>
        </div>
        </div>
      </div>

      {/* Right Panel */}
      <div className="relative flex items-center justify-center w-1/4 p-10">
        <SignIn
            path="/sign-in"
            afterSignInUrl={""}
            afterSignUpUrl={"location.pathname"}
            appearance={{
              elements: {
                rootBox: "w-full max-w-md bg-transparent",
                card: "w-full max-w-md space-y-6 bg-transparent shadow-none",
                headerTitle: "text-2xl font-semibold mb-4 text-white",
                headerSubtitle: "hidden",
                dividerText: "text-sm text-gray-400",
                dividerLine: "border-t border-gray-600",
                formFieldLabel: "text-sm font-medium text-gray-300",
                formFieldInput: "w-full rounded-md bg-customHighlightColor px-4 py-2 text-sm text-white outline-none ring-1 ring-inset ring-customHighlightColor placeholder-gray-500 focus:ring-2 focus:ring-customHighlightColor",
                footerAction: "inline",
                footerActionText: "text-sm text-gray-400 mr-1",
                footerActionLink: "text-sm font-semibold text-blue-400 underline capitalize",
                socialButtonsIconButton: "bg-white",
                formButtonPrimary: "w-full rounded-md bg-customHighlightColor py-2 text-center text-sm font-medium text-white hover:bg-customHighlightColor focus:ring-2 focus:ring-blue-500 normal-case",
                formHeaderTitle: 'text-white',
                formHeaderSubtitle: 'text-white',
                alert: "bg-white",
                identityPreview: "bg-white mt-0",
                main: "mt-0",
                formResendCodeLink: "bg-white p-2 text-black",
                alternativeMethodsBlockButton: 'mt-4 bg-white',
                logoBox: 'hidden',
                socialButtonsBlockButton: 'bg-white',
                footerAction__signIn: 'hidden',
              }
            }}
          />
      </div>
    </div>
  );
}
