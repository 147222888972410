import React, { useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postRequest } from "../utils/httpUtils"; // Assumes you have a utility for POST requests
import { useNotifications } from "../utils/notifications/Notifications";
import CategorySelect from "../utils/CategorySelect";

const PublishApp = () => {
  const { addNotification } = useNotifications();
  const { app_instance_id: appInstanceId } = useParams();
  const [features, setFeatures] = useState([""]);
  const [notes, setNotes] = useState([""]);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleFeatureChange = useCallback((index, value) => {
    const newFeatures = [...features];
    newFeatures[index] = value;
    setFeatures(newFeatures);
  }, [features]);

  const handleCategoryChange = useCallback((e) => {
    setCategory(e.target.value);
  }, []);

  const addFeature = useCallback(() => {
    setFeatures(features => [...features, ""]);
  }, []);

  const removeFeature = useCallback((index) => {
    setFeatures(features => features.filter((_, i) => i !== index));
  }, []);

  const handleNoteChange = useCallback((index, value) => {
    setNotes(notes => {
      const newNotes = [...notes];
      newNotes[index] = value;
      return newNotes;
    });
  }, []);

  const addNote = useCallback(() => {
    setNotes(notes => [...notes, ""]);
  }, []);

  const removeNote = useCallback((index) => {
    setNotes(notes => notes.filter((_, i) => i !== index));
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const payload = {
      features,
      notes,
      description,
      category,
    };

    try {
      const response = await postRequest(`/api/publish_app_instance_to_app/${appInstanceId}`, payload);
      const { data } = response;
      console.log(response);
      if (response && data.new_app_id) {
        addNotification("App evolved successfully", "The app has been evolved successfully", "success");
        navigate(`/apps_l2`); // Redirect to the new app's page
      } else {
        addNotification("Failed to publish app", response.error || "An unexpected error occurred", "error");
        setError(response.error || "An unexpected error occurred");
      }
    } catch (err) {
      addNotification("Failed to publish app", err.message || "Failed to submit the form", "error");
      setError(err.message || "Failed to submit the form");
    } finally {
      setLoading(false);
    }
  }, [appInstanceId, features, notes, description, addNotification, navigate, category]);

  return (
    <div className="flex flex-col gap-4 max-w-xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Publish App</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700 font-bold mb-2">
            Features:
          </label>
          {features.map((feature, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={feature}
                onChange={(e) => handleFeatureChange(index, e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={`Feature ${index + 1}`}
              />
              <button
                type="button"
                onClick={() => removeFeature(index)}
                className="ml-2 bg-red-500 text-white px-2 py-1 rounded"
                disabled={features.length === 1}
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addFeature}
            className="mt-2 bg-customHighlightColor text-white px-4 py-2 rounded"
          >
            Add Feature
          </button>
        </div>
        <div>
          <label className="block text-gray-700 font-bold mb-2">Notes:</label>
          {notes.map((note, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={note}
                onChange={(e) => handleNoteChange(index, e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={`Note ${index + 1}`}
              />
              <button
                type="button"
                onClick={() => removeNote(index)}
                className="ml-2 bg-red-500 text-white px-2 py-1 rounded"
                disabled={notes.length === 1}
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addNote}
            className="mt-2 bg-customHighlightColor text-white px-4 py-2 rounded"
          >
            Add Note
          </button>
        </div>
        <div>
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="description"
          >
            Description:
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter a description"
            rows="3"
            required
          ></textarea>
        </div>
        <div>
          <CategorySelect onChange={handleCategoryChange} />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className={`pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PublishApp;
