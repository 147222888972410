import { useCallback, useState, useEffect } from "react";
import { ssePostRequest } from "../../utils/httpUtils";
import { uniqueId } from "lodash";

const isValidString = str => typeof str === "string" && str.trim().length > 0;

const useFetchFootnotes = (query, params = {}) => {
    const [footnotes, setFootnotes] = useState([]);
    const [footnotesCount, setFootnotesCount] = useState(0);
    const [companiesCount, setCompaniesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loadingIDs, setLoadingIDs] = useState([]);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [messagesFromBackend, setMessagesFromBackend] = useState([]);
    const { footnoteTitles, companies, years, industries, isPublic, appInstanceID } = params;

    const fetchFootnotes = useCallback(async () => {
        const requestID = uniqueId();
        setFootnotes([]); // Reset footnotes before fetching
        setProgressPercentage(0); // Reset progress percentage
        setMessagesFromBackend([]); // Reset messages from backend
        setLoadingIDs(loadingIDs => [...loadingIDs, requestID]); // Mark this request as loading
        // Reusable cleanup function to remove requestID from loadingIDs
        const cleanupLoadingState = () => {
            setLoadingIDs(loadingIDs => loadingIDs.filter(id => id !== requestID));
        };
        // Construct query parameters
        const params = new URLSearchParams();
        params.append("page", currentPage);
        params.append("page_size", pageSize);
        params.append("app_instance_id", appInstanceID);
        if (isValidString(query)) {
            params.append("query", query.trim());
        }
        (footnoteTitles || []).forEach(title => {
            params.append("footnote_title", title);
        });
        (companies || []).forEach(company => {
            params.append("company", company);
        });
        (years || []).forEach(year => {
            params.append("year", year);
        });
        (industries || []).forEach(industry => {
            params.append("industry", industry);
        });
        if (isPublic) {
            params.append("is_public", params.isPublic);
        }
        const queryString = params.toString();
        try {
            ssePostRequest(`/api/footnotes/?${queryString}`, null, {
                onStatus: (statusData) => {
                    console.log("Status Update:", statusData);
                },
                onProgress: ({value}) => {
                    const { step_number, total_steps } = value;
                    const progress = Math.floor((step_number / total_steps) * 100);
                    setProgressPercentage(progress);
                },
                onStreamingText: ({value}) => {
                    setMessagesFromBackend(messages => [...messages, value]);
                },
                onFinal: ({value}) => {
                    // Update footnotes and other states on the final result
                    setFootnotes(value.footnotes);
                    setFootnotesCount(value.count);
                    setCompaniesCount(value.companies_count);
                    setProgressPercentage(100);
                    // Clean up loading state after final result
                    cleanupLoadingState();
                    // setMessagesFromBackend([])
                },
                onError: (errorData) => {
                    console.error("Error occurred:", errorData);
                    // Handle error scenario (e.g., show error message)
                    // Ensure loading state is cleaned up on error
                    cleanupLoadingState();
                },
                onMetadata: (metadataData) => {
                    console.log("Metadata received:", metadataData);
                    // Optionally handle metadata
                },
            });
        } catch (error) {
            console.error("Error setting up SSE request:", error);
            // Cleanup in case of error during the request setup
            cleanupLoadingState();
        }
    }, [currentPage, pageSize, query, footnoteTitles, companies, years, industries, isPublic]);

    useEffect(() => {
        fetchFootnotes();
    }, [currentPage, pageSize]);

    useEffect(() => {
        setCurrentPage(1);
    }, [pageSize]);

    const navigateNext = useCallback(() => {
        setCurrentPage((prevPage) => prevPage + 1);
    }, []);

    const navigatePrevious = useCallback(() => {
        setCurrentPage((prevPage) => prevPage - 1);
    }, []);

    const setPageCallback = useCallback((page) => {
        if (page < 1 || page > Math.ceil(footnotesCount / pageSize)) {
            return;
        }
        setCurrentPage(page);
    }, [footnotesCount, pageSize]);

    const setPageSizeCallback = useCallback((size) => {
        if (size < 1) {
            return;
        }
        setPageSize(size);
    }, []);

    const resetToFirstPage = useCallback(() => {
        setCurrentPage(1);
    }, []);

    const hasNextPage = footnotesCount > currentPage * pageSize;
    const hasPreviousPage = currentPage > 1;
    const totalPages = Math.ceil(footnotesCount / pageSize);

    return {
        footnotes,
        footnotesCount,
        fetchFootnotes,
        navigateNext,
        setCurrentPage: setPageCallback,
        setPageSize: setPageSizeCallback,
        navigatePrevious,
        hasNextPage,
        hasPreviousPage,
        loading: loadingIDs.length > 0,
        totalPages,
        pageSize,
        companiesCount,
        resetToFirstPage,
        messagesFromBackend,
        progressPercentage,
    };
}
export default useFetchFootnotes;