import React, { useCallback, useMemo, useState } from "react";
import BlackComputerScreen from "../utils/BlackComputerScreen";
import StreamingText from "../utils/StreamingText";
import Typed from "react-typed";
import ClipboardButton from "../utils/ClipboardButton";
import { Summary } from "./Summary";
import Feedback from "../utils/Feedback";
import { truncateText } from "../utils/utils";
import { useSelector } from "react-redux";
import { customizationsSelector } from "../redux/reducers/app.reducer";
import useChatExport from "../hooks/useChatExport";
import {
  ArrowDownOnSquareIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import { getBaseURL } from "../utils/utils";

const FileLink = ({
  filename,
  fileID,
  setSource,
  shouldSkipResetOnSourceChangeRef,
}) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      shouldSkipResetOnSourceChangeRef.current = true;
      setSource(fileID);
    },
    [shouldSkipResetOnSourceChangeRef, setSource, fileID]
  );
  const truncatedFilename = useMemo(() => truncateText(filename), [filename]);

  return (
    <h6>
      <a
        className="text-gray-600 hover:text-customHighlightColor no-underline"
        href="#"
        onClick={handleClick}
      >
        {truncatedFilename}
      </a>
    </h6>
  );
};

const PageLink = ({
  page,
  fileID,
  setSource,
  shouldSkipResetOnSourceChangeRef,
  jumpToPage,
}) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      if (typeof setSource === "function") {
        setSource(fileID);
      }
      jumpToPage(page - 1);
      shouldSkipResetOnSourceChangeRef.current = true;
    },
    [setSource, fileID, jumpToPage, shouldSkipResetOnSourceChangeRef]
  );

  return (
    <li>
      <a
        className="text-gray-600 hover:text-customHighlightColor no-underline"
        href="#"
        onClick={handleClick}
      >
        Page {page}
      </a>
    </li>
  );
};

export const ChatRow = ({
  accountingQueryDone,
  accountingQueryOutput,
  appInstanceId,
  chunkLocations,
  fileUploadsWithPages,
  generatedResponse,
  index,
  jumpToPage,
  originalUserText,
  relevantFiles,
  row,
  setSource,
  submitQuery = () => {},
  shouldSkipResetOnSourceChangeRef,
  user,
  userObj,
}) => {
  const isAssistant = row.role === "assistant";
  const isUser = row.role === "user";
  const shouldShowComputerScreen = isAssistant && row.statuses;
  const isApprovedAnswer = row.hasOwnProperty("approved_answer");
  const filteredFileUploadsWithPages = useMemo(
    () =>
      fileUploadsWithPages.filter(
        ({ pages }) => Array.isArray(pages) && pages.length > 0
      ),
    [fileUploadsWithPages]
  );
  const { performExportDocx: performExportDocxInternal, performExportXlsx: performExportXlsxInternal } = useChatExport(accountingQueryOutput);

  const performExportDocx = useCallback(() => {
    performExportDocxInternal(index);
  }, [performExportDocxInternal, index]);

  const performExportXlsx = useCallback(() => {
    performExportXlsxInternal(index);
  }, [performExportXlsxInternal, index]);

  const bypassApprovedAnswersMechanism = useCallback(() => {
    // Approved answers are always natural language queries for now
    // the `true` value marks that we want to bypass the approved
    // answers filter
    submitQuery(row.approved_question, "Natural Language", true,false);
  }, [row.approved_text, submitQuery]);

  const findClosestApprovedAnswerMechanism = useCallback(() => {
    
    const lastUserMessage = accountingQueryOutput
      .slice(0, index)
      .reverse()
      .find(msg => msg.role === 'user');

    if (lastUserMessage) {
      console.log("Finding closest approved answer for:", lastUserMessage.message);
      submitQuery(lastUserMessage.message, "Natural Language", false, true);
    } else {
      console.error("Could not find the user's question to find the closest approved answer.");
    }
  }, [accountingQueryOutput, index, submitQuery]);


  const [sourcesVisible, setSourcesVisible] = useState(false);
  const toggleSource = useCallback(
    () => setSourcesVisible((sourcesVisible) => !sourcesVisible),
    [setSourcesVisible]
  );

  const { name: companyName } = useSelector(customizationsSelector);

  return (
    <div className="flex w-full">
      {accountingQueryOutput.length > 0 && (
        <div className="flex mb-4 w-full">
          {isAssistant && (
            <img
              className="h-8 w-8 ml-2 rounded-full bg-black"
              src={`https://app.tellen.ai/favicon.ico`}
              alt=""
            />
          )}

          <div className="w-full">
            {shouldShowComputerScreen && (
              <div className="mx-2">
                <BlackComputerScreen lines={row.statuses} />
              </div>
            )}
            <div className="flex flex-1 mx-2 bg-white rounded-lg shadow">
              <p className="self-start m-3 text-sm text-gray-800">
                <StreamingText text={row.message} done={row.completed} />
                {row.message === "" && isAssistant && !row.completed && (
                  <>
                    <Typed strings={["..."]} loop typeSpeed={40} />
                  </>
                )}
                {accountingQueryDone && isAssistant && (
                  <>
                    <br />
                    <ClipboardButton text={row.message} />
                  </>
                )}
                {isAssistant && chunkLocations.length > 0 && (
                  <p>
                    <br />
                    <b>
                      Background and source
                      {chunkLocations.length > 1 ? "s" : ""}:
                      <button onClick={toggleSource}>
                        {sourcesVisible ? (
                          <ChevronDownIcon
                            className="h-4 w-4"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronLeftIcon
                            className="h-4 w-4"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </b>
                    <br />
                    {sourcesVisible &&
                      filteredFileUploadsWithPages.map(
                        ({ filename, pages, fileID, filetype }) => (
                          <div className="mb-2" key={fileID}>
                            <FileLink
                              filename={filename}
                              fileID={fileID}
                              setSource={setSource}
                              shouldSkipResetOnSourceChangeRef={
                                shouldSkipResetOnSourceChangeRef
                              }
                            />
                            {
                              // Temporary check for PDF files due to current confusion with filetypes.
                              // We are supporting DOCX files now, but we're facing a limitation where we don't get the page numbers for these files.
                              // This prevents us from showing incorrect information by not processing DOCX files in the same way as PDFs.
                              // We plan to address the DOCX file's page numbers issue in the future.
                              filetype === "pdf" &&
                                filename.toLowerCase().endsWith(".pdf") && (
                                  <div className="flex space-x-2">
                                    <ul>
                                      {pages.map((page) => (
                                        <PageLink
                                          key={page}
                                          page={page}
                                          fileID={fileID}
                                          setSource={setSource}
                                          shouldSkipResetOnSourceChangeRef={
                                            shouldSkipResetOnSourceChangeRef
                                          }
                                          jumpToPage={jumpToPage}
                                        />
                                      ))}
                                    </ul>
                                  </div>
                                )
                            }
                          </div>
                        )
                      )}
                  </p>
                )}
                {row.file_url && (
                  // This is when the backend is giving the user a file to download
                  <p>
                    <a
                      href={getBaseURL() + row.file_url}
                      className="flex items-center space-x-2 text-customHighlightColor no-underline"
                    >
                      <ArrowDownOnSquareIcon
                        className="h-4 w-4 shrink-0 text-customHighlightColor"
                        aria-hidden="true"
                      />
                      <span>Click here to download the file.</span>
                    </a>
                  </p>
                )}
                {row.approved_question && row.approved_answer && (
                  // This is when an approved answer is given
                  <p>
                    <i>
                      The following question–answer pair is pre-approved by{" "}
                      {companyName}:
                    </i>
                    <br />
                    <br />
                    Original question, similar to yours:
                    <br />
                    <b>{row.approved_question}</b>
                    <br />
                    <br />
                    Approved answer:
                    <br />
                    <b>{row.approved_answer}</b>
                    <br />
                    <ClipboardButton text={row.approved_answer} />
                    <br />
                    <br />
                    AI-generated based on approved answer: <br />
                    <b>{row.dynamic_answer}</b>
                    <br />
                    <ClipboardButton text={row.dynamic_answer} />{" "}
                    {isApprovedAnswer && (
                      <>
                        <br />
                        <br />
                        <button
                          type="button"
                          className="ml-2 flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
                          onClick={bypassApprovedAnswersMechanism}
                        >
                          Bypass approved question–answer pairs and query AI
                          directly.
                        </button>
                      </>
                    )}
                  </p>
                )}
                {row.error && (
                  <p className="font-bold">{row.error_message}</p>
                )}
                {sourcesVisible && accountingQueryDone && isAssistant && (
                  <p>
                    <i>
                      How it works: First, we summarize the files or webpages
                      you uploaded. Then, we match these summaries to your query
                      to identify the most relevant documents. Next, we
                      thoroughly search these selected documents for any text or
                      tables that could help answer your query. Here are the
                      documents we initially chose to search through:{" "}
                    </i>
                    <br />
                    <br />
                    <ul>
                      {relevantFiles.map((file) => (
                        <li key={file.data.file_id}>{file.data.filename}</li>
                      ))}
                    </ul>
                  </p>
                )}
                {(accountingQueryDone && isAssistant) && (
                  <div className="inline-block mb-2">
                    <button
                      type="button"
                      className="ml-2 p-2 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
                      onClick={performExportXlsx}>
                      Save as XLSX
                    </button>

                    <button
                      type="button"
                      className="ml-2 p-2 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
                      onClick={performExportDocx}>
                      Save as DOCX
                    </button>
                  
                    {!isApprovedAnswer && (
                      
                      <p>
                        <br />
                      <button
                        type="button"
                        className="ml-2 flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
                        onClick={findClosestApprovedAnswerMechanism}
                      >
                        Find closest approved answer
                      </button>
                    </p>
                   
                    )}
                    

                  </div>
                )}
                {accountingQueryDone && isAssistant && row.metadata && (
                  <Summary metadata={row.metadata} />
                )}
                {accountingQueryDone && isAssistant && (
                  <Feedback
                    userObj={userObj}
                    appInstanceId={appInstanceId}
                    originalUserText={originalUserText}
                    generatedResponse={generatedResponse}
                  />
                )}
              </p>
            </div>
          </div>
          {isUser && (
            <img
              className="h-8 w-8 mr-2 rounded-full bg-gray-50"
              src={user["imageUrl"]}
              alt=""
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ChatRow;