import React from "react";
import AppCardLong from "./AppCardLong";

const AppsListLong = ({ pageName, appsArray }) => {
  return (
    <div className="flex flex-col gap-4 w-full max-w-screen-xl mx-auto px-4">
      <div className="bg-white">
        <h1 className="text-2xl font-bold">{pageName}</h1>
      </div>
      <div className="flex flex-col items-center w-full">
        {Array.isArray(appsArray) &&
          appsArray.map((card) => (
            <div className="w-full max-w-2xl">
              <AppCardLong
                key={card.app_instance_id}
                card={card}
                url={card.url}
                slug={card.slug}
                description={card.description}
                cta_text="Go"
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AppsListLong;
