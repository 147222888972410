import React from "react";
import Dropzone from "./Dropzone";
import URLSubmitForm from "./URLSubmitForm";

 // https://react-dropzone.org/#!/Accepting%20specific%20file%20types

const FileUpload = ({
  onDrop,
  handleURLSubmit,
  urlInput,
  hideDropzone = false,
  hideURLInput = false,
  testID = "dropzone"
}) => {
  return (
    <div className="flex justify-center">
      <div
        className="flex flex-col space-y-4"
        data-testid={testID}
        id="dropzone-filetype"
      >
        {!hideDropzone && <Dropzone onDrop={onDrop} />}
        {!hideURLInput && (
          <URLSubmitForm
            handleURLSubmit={handleURLSubmit}
            urlInput={urlInput}
          />
        )}
      </div>
    </div>
  );
};

export default FileUpload;
