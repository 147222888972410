import React from "react";

const Changelog = () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="bg-white">
        <h1 className="text-2xl font-bold">Technical Changelog</h1>
      </div>

      <h2 className="text-2xl font-bold">2024-08-30 - 0.6.1</h2>
      <ul class="list-disc">
        <li>Footnote Extractor App</li>
        <li>
          Adds tagging, automatic similar question generation, and other
          enhancements to approved answers mechanism
        </li>
        <li>Alembic now running automatically on container start</li>
        <li>Generalizes known origins</li>
        <li>Ensures SENTRY_DSN on all environments as env variable</li>
        <li>Updates Python and JavaScript dependencies</li>
        <li>Allows hiding of app instances in left menu</li>
        <li>Allows ordering of apps</li>
      </ul>

      <h2 className="text-2xl font-bold">2024-07-21 - 0.6.0</h2>
      <ul class="list-disc">
        <li>Adds approved answers mechanism</li>
        <li>
          Includes frontend review process and automated question/answer
          generation from documents
        </li>
      </ul>

      <h2 className="text-2xl font-bold">2024-07-24 - 0.5.8</h2>
      <ul class="list-disc">
        <li>Adds ability to generate a Word document</li>
        <li>Also add ability to take in Word document as template</li>
        <li>Paginates PCAOB app</li>
        <li>Fixes embedding mechanism</li>
      </ul>

      <h2 className="text-2xl font-bold">2024-07-17 - 0.5.7</h2>
      <ul class="list-disc">
        <li>Adds ability in chatbot to produce Excel files when prompted.</li>
      </ul>

      <h2 className="text-2xl font-bold">2024-07-17 - 0.5.7</h2>
      <ul class="list-disc">
        <li>Adds ability in chatbot to produce Excel files when prompted.</li>
      </ul>

      <h2 className="text-2xl font-bold">2024-07-15 - 0.5.6</h2>
      <ul class="list-disc">
        <li>Adds ability to upload Excel files to custom chatbot.</li>
      </ul>

      <h2 className="text-2xl font-bold">2024-07-15 - 0.5.5</h2>
      <ul class="list-disc">
        <li>
          Consolidates chatbots, adds PCAOB report infrastructure and app.
        </li>
      </ul>

      <h2 className="text-2xl font-bold">2024-06-21 - 0.5.4</h2>
      <ul class="list-disc">
        <li>Adds ability for user to publish apps</li>
        <li>
          More RAG techniques including query rewriting to actually get the key
          point out of it, dynamic thresholds in the chunks.
        </li>
        <li>API endpoints for proprietary data chat.</li>
        <li>Slack chat threading</li>
        <li>More logging</li>
        <li>PCAOB Analyzer app</li>
      </ul>

      <h2 className="text-2xl font-bold">2024-06-13 - 0.5.3</h2>
      <ul class="list-disc">
        <li>Slack app</li>
        <li>PCAOB app</li>
        <li>Conversation history</li>
        <li>Redesigned proprietary data chatbot</li>
        <li>Ability to upload and interrogate Word files</li>
        <li>Summary of documents for RAG</li>
      </ul>
    </div>
  );
}

export default Changelog
