import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux"
import AppCard from "./appsPage/AppCard";
import { appsSelector, getApps } from "./redux/reducers/apps.reducer";
import { useUser } from "@clerk/clerk-react";

const Apps = ({layer}) => {
  const dispatch = useDispatch()
  const apps = useSelector(appsSelector)
  const { isSignedIn } = useUser();
  const ctaText = isSignedIn ? "Create" : "Sign in and create";

  useEffect(() => {
    dispatch(getApps({ layer: layer }));
  }, [layer]);

  const [filterCategory, setFilterCategory] = useState(null);
  // const categories = ["All", "General", "Audit", "Tax", "Accounting"];

  // NOTE: Currently we don't have apps with category "Accounting"
  const categories = ["All", "General", "Audit", "Tax"];

  const filteredApps = useMemo(() => {
    if (!apps) return [];
    return apps.filter(
      (card) => !filterCategory || card.category === filterCategory
    );
  }, [apps, filterCategory]);
  const title = layer === 1 ? "App Builder" : "App Store";
  const appsDescription =
    layer === 1
      ? "Use these tools to build applications."
      : "Explore apps across service lines built by Tellen and our expert network.";

  return (
    <div className="flex flex-col gap-2">
      <div className="bg-white">
        <h1 className="text-2xl font-bold">{title}</h1>
        <p className="mb-5">
          {appsDescription}
        </p>
      </div>
      {layer === 2 && (
        <div data-testid="categories" className="flex space-x-4 p-4">
          {categories.map((category) => (
            <button
              key={category}
              data-testid="category-item"
              onClick={() => {
                setFilterCategory(
                  category === "All" ? null : category.toLowerCase()
                );
              }}
              className={`cursor-pointer ${
                filterCategory === category.toLowerCase() ||
                (category === "All" && filterCategory === null)
                  ? "text-customHighlightColor font-bold"
                  : "text-customDarkGray"
              }`}
            >
              {category}
            </button>
          ))}
        </div>
      )}
      <div
        data-testid="card-items"
        className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
      >
        {filteredApps.map((card) => (
          <AppCard
            key={card.slug}
            card={card}
            url={`/app/view/${card.slug}`}
            cta_text={ctaText}
            slug={card.slug}
          />
        ))}
      </div>
    </div>
  );
};

export default Apps;
