import { postRequest, getRequest } from "./httpUtils";
import { v4 as uuidv4 } from "uuid";

/**
 * Ensures that the given callback is a function. If the callback is not a function,
 * it returns a no-operation function. This is useful for preventing errors when
 * expecting a function as a parameter and receiving something else.
 *
 * @param {*} cb - The callback to ensure is a function.
 * @returns {Function} The original callback if it is a function, or a no-operation function otherwise.
 */
export const ensureFunction = (cb) => {
  if (typeof cb !== "function") {
    return () => {};
  }
  return cb;
};

/**
 * Sanitizes a given filename by replacing all characters that are not alphanumeric, underscore, period, or hyphen
 * with an underscore. This ensures that the filename is safe to use in file systems that may not support certain
 * special characters.
 *
 * @param {string} filename - The original filename to sanitize.
 * @returns {string} The sanitized filename with all unsafe characters replaced by underscores.
 */
export const secureFilename = (filename) =>
  filename.replace(/[^a-zA-Z0-9_.-]/g, "_");

// This is super important for the frontend to know where to send requests when running locally. It's not relevant in production.
export const getBaseURL = () => {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname.endsWith(".ngrok.io")
  ) {
    return "http://127.0.0.1:5000"; // This is the correct path of the Flask backend when running locally.
  } else {
    return "";
  }
};

/**
 * Checks if a user has an email address associated with 'tellen.ai' domain.
 *
 * This function takes a user object and checks if the user's primary email address
 * contains the domain '@tellen.ai'. It safely handles cases where the user or
 * email address fields may be undefined, thanks to optional chaining.
 *
 * Don't forget that this is on the frontend, so can easily be manipulated by a user!
 *
 * @param {Object} user - The user object to check. This object should have a
 *                        'primaryEmailAddress' property, which in turn should
 *                        contain an 'emailAddress' property.
 *
 * @returns {boolean} - Returns true if the user's primary email address includes
 *                      '@tellen.ai', otherwise returns false. If the user object
 *                      or the email address is not defined, it also returns false.
 */
export const isTellenUser = (user) => {
  const email = user?.primaryEmailAddress?.emailAddress;
  const fullName = user?.fullName;

  const tellenEmails = ["jonwelzbacher@gmail.com", "@tellen.ai"];
  const tellenNames = ["Deepak Lalit", "Jason Jones"];

  return (
    tellenEmails.some((allowedEmail) => email?.endsWith(allowedEmail)) ||
    tellenNames.includes(fullName)
  );
};

/**
 * Converts an array of URLs to an array of objects containing the URL and the filename.
 *
 * @param {string[]} urls - An array of URLs to be converted.
 * @returns {Object[]} An array of objects, each containing a 'url' and a 'filename'.
 */
export const convertSampleFileURLsToObjects = (urls) =>
  urls.map((url) => {
    const filename = decodeURIComponent(url.split("/").pop());
    return { url, filename };
  });

/**
 * Triggers a file download by sending a POST request to a specified path with JSON data.
 * The server is expected to respond with a blob representing the file content.
 * This function creates a temporary anchor element to initiate the download.
 *
 * @function handleDownload
 * @async
 * @param {string} path - The URL path to send the POST request to.
 * @param {string} extension - The file extension to use when naming the downloaded file.
 * @param {Object|Array<Object>} jsonData - The JSON data to be sent to the server.
 * @param {string} [method="POST"] - The HTTP method to use for the request. Defaults to "POST".
 * @returns {Promise<void>} A promise that resolves once the download is triggered.
 * @throws {Error} If the download fails due to network issues or server errors.
 *
 * @example
 * // Assuming the server generates a PDF file from the JSON data
 * const jsonData = { key: 'value' };
 * handleDownload('/generate_pdf', 'pdf', jsonData)
 *   .then(() => console.log('Download started'))
 *   .catch(err => console.error('Download failed:', err));
 */
export const handleDownload = async (
  path,
  extension,
  jsonData,
  method = "POST"
) => {
  try {
    let response;
    if (method === "POST") {
      response = await postRequest(path, jsonData, "application/json", "blob");
    } else if (method === "GET") {
      response = await getRequest(path, "application/json", "blob");
    } else {
      throw new Error(`Invalid method ${method} provided`);
    }
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `table.${extension}`); // Set the file name with the given extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error(`Download failed: ${error}`);
  }
};

/**
 * Asynchronously triggers a file download for a CSV file generated from the provided JSON data.
 * It posts the JSON data to a specified endpoint ("/api/download_csv"), receives the generated CSV
 * as a blob, creates a URL for it, and triggers the download in the browser.
 *
 * @param {Array<Object>|Object} jsonData - The JSON data to be sent to the server for CSV generation.
 * This can be an array of objects where each object represents a row in the CSV or a single object.
 * 
 * @returns {void} This function does not return a value. It initiates a file download directly in the browser.
 * 
 * @example
 * const data = [
 *   { name: "John Doe", email: "john@example.com" },
 *   { name: "Jane Doe", email: "jane@example.com" }
 * ];
 * 
 * <button
    onClick={() =>
      handleCSVDownload(data)
    }
  >
    Download as CSV
  </button>
 *
 * @throws {Error} Logs an error message to the console if the download fails.
 */
export const handleCSVDownload = (jsonData) =>
  handleDownload("/api/download_csv", "csv", jsonData);

/**
 * Asynchronously triggers a file download for an XLSX file generated from the provided JSON data.
 * It posts the JSON data to the "/api/download_xlsx" endpoint, receives the generated XLSX as a blob,
 * creates a URL for it, and triggers the download in the browser.
 *
 * @function handleXlsxDownload
 * @async
 * @param {Array<Object>|Object} jsonData - The JSON data to be sent to the server for XLSX generation.
 * This can be an array of objects where each object represents a row in the XLSX or a single object.
 *
 * @returns {Promise<void>} A promise that resolves once the download is triggered.
 * @throws {Error} Logs an error message to the console if the download fails.
 *
 * @example
 * const data = [
 *   { name: "John Doe", email: "john@example.com" },
 *   { name: "Jane Doe", email: "jane@example.com" }
 * ];
 *
 * <button
 *     onClick={() =>
 *       handleXlsxDownload(data)
 *     }
 * >
 *   Download as XLSX
 * </button>
 */
export const handleXlsxDownload = (jsonData) =>
  handleDownload("/api/download_xlsx", "xlsx", jsonData);

/**
 * Get a random subset of specified size from an array.
 * If the requested size is greater than the array's length, the entire array is returned.
 * @param {Array} array The original array from which to get a subset.
 * @param {number} size The size of the subset.
 * @return {Array} A random subset of the array or the original array if size exceeds array length.
 */
export const getRandomSubset = (array, size) => {
  if (size >= array.length) {
    return array;
  }

  const shuffled = array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
  return shuffled.slice(0, size);
};

/**
 * Validates and formats a string as a hexadecimal color.
 *
 * This function checks if the given string `s` is a valid hexadecimal color code.
 * If it is valid but does not start with the '#' symbol, the function formats the string
 * by prefixing it with '#'. If the string is already a valid hex color code that starts
 * with '#', or if it does not match the hex color pattern, the original string is returned.
 *
 * @param {string} s - The string to validate and format as a hex color.
 * @return {string} - The formatted hex color string if valid and necessary, otherwise the original string.
 */
export const makeHexColor = (s) => {
  const hexColorPattern = /^#?([a-fA-F0-9]{6})$/;
  if (hexColorPattern.test(s) && !s.startsWith("#")) {
    return "#" + s;
  }
  return s;
};

export const hexToRgbString = (hex) => {
  // Remove the leading '#' if present
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  let r, g, b;

  if (hex.length === 3) {
      // Handle shorthand notation (e.g., #abc)
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
      // Handle full notation (e.g., #aabbcc)
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
  } else {
      throw new Error('Invalid hex color format');
  }

  // Return the color as a comma-separated string
  return `${r}, ${g}, ${b}`;
}

export const classNames = (...classes) => classes?.filter(Boolean).join(" ");

export const getFileExt = (filename) => filename?.split(".").pop();

export function stripHTTP(text) {
  return text.replace("https://", "").replace("www.", "");
}

export function truncateText(text) {
  if (text) {
    let output = stripHTTP(text);
    if (output.length < 80) {
      return output;
    } else {
      return `${output.substring(0, 35)} ... ${output.substring(
        output.length - 35
      )}`;
    }
  }
}

export const errorResponseHandler = (e) => {
  console.info(e);
  if (e.response) {
    throw new Error(e.response.data.message);
  } else {
    throw new Error(e);
  }
};

/**
 * Generates an account query message object with a unique UUID.
 *
 * This function creates an object that represents an account query message, including a unique identifier (UUID),
 * the message content, the role associated with the message, and a flag indicating whether the message is completed.
 * The UUID is generated using the `uuidv4` function from the `uuid` library, ensuring each message has a unique identifier.
 *
 * @function generateAccountQueryMessage
 * @param {string} message - The content of the account query message.
 * @param {string} role - The role associated with the account query message. Can be "user", "assistant" or "system"
 * @param {boolean} [completed=true] - A flag indicating whether the message is completed. Defaults to `true`.
 * @returns {Object} An object containing the message, role, completion status, and a unique UUID.
 * @example
 * const messageObj = generateAccountQueryMessage("Query about account balance", "user", false);
 * console.log(messageObj);
 * // Output: { message: "Query about account balance", role: "user", completed: false, uuid: "123e4567-e89b-12d3-a456-426614174000" }
 */
export const generateAccountQueryMessage = (
  message,
  role,
  completed = true
) => ({ message, role, completed, uuid: uuidv4() });

/**
 * Formats messages retrieved from the database.
 * This function takes an array of message objects, typically fetched from the database,
 * and applies the `generateAccountQueryMessage` function to each item to enrich them with a unique UUID
 * and a default completion status. The result is an array of formatted message objects suitable for UI rendering or further backend processing.
 *
 * @param {Array} array - An array of message objects fetched from the database. Each object must include at least a `message` and `role` property.
 * @returns {Array} An array of formatted message objects, enhanced with a unique UUID and a default completion status set to `true`.
 * @example
 * const dbMessages = [
 *   { message: "How do I reset my password?", role: "user" },
 *   { message: "I need help with my account settings.", role: "user" }
 * ];
 * const formattedDbMessages = formatMessagesFromDatabase(dbMessages);
 * console.log(formattedDbMessages);
 * // Output: [{ message: "How do I reset my password?", role: "user", completed: true, uuid: "another-unique-id-1" }, { message: "I need help with my account settings.", role: "user", completed: true, uuid: "another-unique-id-2" }]
 */
export const formatMessagesFromDatabase = (array) =>
  array.map(({ message, role }) => generateAccountQueryMessage(message, role));

/**
 * Finds the last element in an array that satisfies a given predicate function.
 * Iterates over the array from the end to the beginning and returns the first element
 * that matches the predicate. If no element matches the predicate, it returns `undefined`.
 *
 * @param {Array} array - The array to search through.
 * @param {Function} predicate - The function to test each element of the array.
 * @returns {*} The last element in the array that satisfies the predicate, or `undefined` if no such element is found.
 *
 * @example
 * const array = [1, 2, 3, 4, 5];
 * const predicate = (num) => num % 2 === 0;
 * const lastEvenNumber = findLast(array, predicate);
 * console.log(lastEvenNumber); // Output: 4
 */
export const findLast = (array, predicate) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i])) {
      return array[i];
    }
  }
  return undefined; // Return undefined if no element satisfies the condition
};

/**
 * Creates a deep copy of a serializable object.
 * This function uses `JSON.stringify` to convert the object into a JSON string, and then `JSON.parse` to convert it back into an object.
 * This method is effective for creating a deep copy of objects that are serializable, meaning they can be converted to a JSON string and back without losing information.
 * However, it's important to note that this method has limitations:
 * - It does not work for non-serializable objects, such as functions (including those with closures), Symbols, objects that represent HTML elements in the HTML DOM API, recursive data structures, and many other cases.
 * - It may not accurately copy objects that contain complex data types or circular references, as these are not supported by the JSON format.
 *
 * @param {Object} serializable - The serializable object to be deep copied.
 * @returns {Object} A deep copy of the input object.
 * @throws {SyntaxError} If the input object contains circular references, `JSON.stringify` will throw a `SyntaxError`.
 * @example
 * const original = { a: 1, b: { c: 2 } };
 * const copy = deepCopy(original);
 * console.log(copy); // Output: { a: 1, b: { c: 2 } }
 */
export const deepCopy = (serializable) =>
  JSON.parse(JSON.stringify(serializable));

// Helper function to add leading zeros if necessary
const twoDigits = (num) => num.toString().padStart(2, "0");

/**
 * Formats a given timestamp into a human-readable string with a specific format.
 * The formatted string includes the date, time, and uses an em space character for wider spacing between the date and time components.
 *
 * @param {number|string} timestamp - The timestamp to format. This can be a number representing milliseconds since the Unix epoch or a string that can be parsed into a date.
 * @returns {string} A formatted string representing the date and time of the given timestamp. The format is "YYYY-MM-DD HH:MM:SS", where "YYYY" is the four-digit year, "MM" is the two-digit month, "DD" is the two-digit day, "HH" is the two-digit hour, "MM" is the two-digit minute, and "SS" is the two-digit second. An em space character is used for wider spacing between the date and time components.
 * @example
 * // Assuming the current date and time is 2023-04-01 12:34:56
 * const formattedTimestamp = formatTimestamp(Date.now());
 * console.log(formattedTimestamp); // Output: "2023-04-01  12:34:56"
 */
export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  // Get the user's timezone offset in hours
  const timezoneOffset = date.getTimezoneOffset() / 60;

  // Formatting the date and time components in the user's local timezone
  const formattedDate = date.toLocaleDateString();
  const formattedTime = date.toLocaleTimeString();

  // Use an em space character for wider spacing
  const emSpace = "\u2003";

  // Constructing the formatted date-time string with an em space character
  return `${formattedDate}${emSpace}${formattedTime} (UTC${
    timezoneOffset >= 0 ? "-" : "+"
  }${Math.abs(timezoneOffset)})`;
};

/**
 * Removes the first element in an array that satisfies a given predicate.
 * Returns a new array with the removed element and does not mutate the original array.
 *
 * @param {Array} arr - The original array.
 * @param {Function} predicate - The function to test each element of the array.
 * @returns {Array} A new array with the first element satisfying the predicate removed.
 */
export const removeFirstMatchingElement = (arr, predicate) => {
  // Create a shallow copy of the array to avoid mutating the original array
  const newArr = [...arr];

  // Iterate through the copied array
  for (let i = 0; i < newArr.length; i++) {
    // Check if the current element satisfies the predicate
    if (predicate(newArr[i])) {
      // Remove the element from the copied array
      newArr.splice(i, 1);
      // Break the loop after finding the first matching element
      break;
    }
  }

  return newArr;
};

export const getAppURL = (data) => {
  let url;
  if (data.custom_app) {
    url = `/${data.based_on}/${data.app_instance_id}`;
  } else {
    url = `/${data.slug}/${data.app_instance_id}`;
  }
  return url.replace(/\/null$/, "");
};

export const formatNum = (number) => {
  if (number) {
    number = Number(number);
    return new Intl.NumberFormat("en-US").format(number);
  }
  return number;
};

/**
 * Finds the last assistant message in the accounting query output and updates it.
 * This is a utility function
 *
 * @param {Array} accountingQueryOutput - Current accounting query messages.
 * @param {function} updateFunction - Callback to update the found assistant message.
 * @returns {Array} Updated accounting query output.
 */
export const updateLastAssistantMessage = (
  accountingQueryOutput,
  updateFunction
) => {
  const newOutput = deepCopy(accountingQueryOutput);
  const lastAssistantMessage = findLast(
    newOutput,
    (message) => message.role === "assistant"
  );
  if (lastAssistantMessage) {
    updateFunction(lastAssistantMessage);
  }
  return newOutput;
};

export const isIterable = (x) => {
  return x != null && typeof x[Symbol.iterator] === "function";
};

export const toTitleCase = (str) => {
  const lowerCaseWords = [
    "of",
    "in",
    "and",
    "the",
    "on",
    "at",
    "to",
    "for",
    "with",
    "but",
    "or",
    "nor",
    "a",
    "an",
  ];

  return str
    .toLowerCase()
    .split(" ")
    .map((word, index) => {
      if (index !== 0 && lowerCaseWords.includes(word)) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};