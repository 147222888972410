import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import FootnotesList from "./FootnotesList";
import FilesTable from "./FilesTable";
import FileUpload from "../../utils/FileUpload/FileUpload";
import { handleFileUpload } from "../../utils/FileUpload/handleFileUpload";
import { useUser } from "@clerk/clerk-react";
import {
  NotificationType,
  useNotifications,
} from "../../utils/notifications/Notifications";
import Dialog from "../../utils/Dialog";
import useFetchFootnotes from "./useFetchFootnotes";
import useFetchFootnoteFiles from "./useFetchFootnoteFiles";
import { extractData } from "../../utils/extractData";
import useTabs from "../../hooks/useTabs";
import Tabs from "../../utils/Tabs";
import { toTitleCase } from "../../utils";
import { formatNum } from "../../utils";
import { MessageForm, Select } from "../../components";
import useFetchFootnoteFiltersData from "../../hooks/useFetchFootnoteFiltersData";
import SignInModal from "../../utils/SignInModal";
import BlackComputerScreen from "../../utils/BlackComputerScreen";
import Progress from "../../utils/Progress";
import { Link } from "react-router-dom";

const footnotesTabs = [
  { id: "footnotes", label: "Search Footnotes" },
  // { id: "files", label: "File Manager" },
  // { id: "generator", label: "Footnote Generator" },
];

const FootnotesDatabase = ({ isPublic = false }) => {
  const [query, setQuery] = useState("");

  // States used for filtering
  const [selectedFootnoteTitles, setSelectedFootnoteTitles] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [sampleQueryClicked, setSampleQueryClicked] = useState(false);
  const [queryRunning, setQueryRunning] = useState(false);
  const selectedFootnoteTitleValues = useMemo(
    () => selectedFootnoteTitles.map(({ value }) => value),
    [selectedFootnoteTitles]
  );
  const selectedCompanyValues = useMemo(
    () => selectedCompanies.map(({ value }) => value),
    [selectedCompanies]
  );
  const selectedYearValues = useMemo(
    () => selectedYear.map(({ value }) => value),
    [selectedYear]
  );
  const selectedIndustryValues = useMemo(
    () => selectedIndustry.map(({ value }) => value),
    [selectedIndustry]
  );
  // End of states used for filtering

  const { footnoteFiles, fetchFootnoteFiles } = useFetchFootnoteFiles();

  const {
    years,
    companies,
    footnoteTitles,
    industries,
    refetch: refetchFiltersData,
  } = useFetchFootnoteFiltersData();

  const { appInstanceData } = useAppInstanceData();

  const {
    footnotes,
    fetchFootnotes,
    hasNextPage,
    hasPreviousPage,
    navigatePrevious,
    navigateNext,
    loading,
    setPageSize,
    pageSize,
    footnotesCount,
    companiesCount,
    messagesFromBackend,
    progressPercentage,
    resetToFirstPage,
  } = useFetchFootnotes(query, {
    footnoteTitles: selectedFootnoteTitleValues,
    companies: selectedCompanyValues,
    years: selectedYearValues,
    industries: selectedIndustryValues,
    isPublic,
    appInstanceID: appInstanceData.id,
  });

  const [uploading, setUploading] = useState(false);
  const [parsing, setParsing] = useState(false);
  const [activeTab, handleTabChange] = useTabs(footnotesTabs, "footnotes");

  useEffect(() => {
    // This useEffect triggers a refetch of the filters data whenever the active tab changes.
    // It particularly aims to address scenarios where the user switches between the "files" and "footnotes" tabs.
    // This ensures that if the user has uploaded a new file in the "files" tab, the filters data is updated accordingly.
    // When the user goes back to the "footnotes" tab, the filters data will be up-to-date.
    if (activeTab === "footnotes") {
      refetchFiltersData();
    }
  }, [activeTab]);

  const { isSignedIn, user } = useUser();
  const { addNotification } = useNotifications();

  const showFilesTab = activeTab === "files";
  const showFootnotesTab = activeTab === "footnotes";

  const [filteredRows, setFilteredRows] = useState(null);

  const handleSubmitMessageForm = useCallback(
    async (e) => {
      e.preventDefault();

      if (!isSignedIn) {
        console.log("Not signed in.");
        setSignInModalOpen(true);
      } else {
        resetToFirstPage();
        try {
          await fetchFootnotes(query);
        } catch (error) {
          addNotification(
            "Error submitting your query.",
            "",
            NotificationType.error
          );
          console.error("Error fetching data:", error);
        } finally {
          setQueryRunning(false);
        }
      }
    },
    [isSignedIn, query, fetchFootnotes, resetToFirstPage]
  );

  const samples = [
    {
      id: 1,
      title:
        "Find disclosures where companies updated their lease accounting policies due to ASC 842 adoption.",
      views: "2k",
    },
    {
      id: 2,
      title:
        "Show footnotes describing changes in fair value measurement for complex financial instruments.",
      views: "1.6k",
    },
    {
      id: 3,
      title:
        "Search for disclosures of off-balance sheet lease commitments across multiple business units.",
      views: "1.5k",
    },
    {
      id: 4,
      title:
        "Find disclosures highlighting new long-term lease agreements for key operational facilities.",
      views: "1.5k",
    },
    {
      id: 5,
      title:
        "Identify disclosures addressing environmental liabilities tied to future climate change regulations.",
      views: "1.5k",
    },
  ];

  const [signInModalOpen, setSignInModalOpen] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      await handleFileUpload(
        acceptedFiles,
        { user, addNotification },
        {
          preAction: () => {
            setUploading(true);
            setDialogOpen(false);
          },
          thenAction: ({ response }) => {
            setUploading(false);
            setParsing(true);

            try {
              extractData(response.data["file_id"], {
                onStatus: (jsonPayload) => {
                  console.log("Status:", jsonPayload);
                },
                onFinal: (jsonPayload) => {
                  console.log("Final:", jsonPayload);
                  if ("error" in jsonPayload.value) {
                    addNotification(
                      "No data found.",
                      "",
                      NotificationType.error
                    );
                  } else {
                    // addNewElement(fileID, {
                    //   financialStatements:
                    //     jsonPayload.value["financial_statements"],
                    //   footnotes: jsonPayload.value["footnotes"],
                    // });
                    // selectElementByFileID(fileID);
                    addNotification(
                      "Document parsed.",
                      "",
                      NotificationType.success
                    );
                    setParsing(false);
                    fetchFootnotes();
                    fetchFootnoteFiles();
                  }
                },
                onError: (error) => {
                  console.error("Error:", error);
                  addNotification(
                    "Error parsing document.",
                    "",
                    NotificationType.error
                  );
                },
              });
            } catch (error) {
              addNotification(
                "Error submitting your query.",
                "",
                NotificationType.error
              );
              console.error("Error fetching data:", error);
            }
          },
        }
      );
    },
    [user, addNotification, fetchFootnotes, fetchFootnoteFiles]
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = useCallback(() => setDialogOpen(true), []);
  const closeDialog = useCallback(() => setDialogOpen(false), []);

  const onChangePageSize = useCallback(
    (e) => {
      setPageSize(parseInt(e.target.value));
    },
    [setPageSize]
  );

  const [shouldFetchFootnotes, setShouldFetchFootnotes] = useState(false);

  const handleSelectionChange = useCallback(
    (option, setSelectedFunction) => {
      setSelectedFunction(option);
      setQuery(""); // Reset query
      resetToFirstPage(); // Reset page number
      setShouldFetchFootnotes(true); // Trigger fetchFootnotes in useEffect
    },
    [setQuery, resetToFirstPage, setShouldFetchFootnotes]
  );

  const setSelectedFootnoteTitleInternal = useCallback(
    (option) => handleSelectionChange(option, setSelectedFootnoteTitles),
    [setSelectedFootnoteTitles, handleSelectionChange]
  );

  const setSelectedCompanyInternal = useCallback(
    (option) => handleSelectionChange(option, setSelectedCompanies),
    [setSelectedCompanies, handleSelectionChange]
  );

  const setSelectedYearInternal = useCallback(
    (option) => handleSelectionChange(option, setSelectedYear),
    [setSelectedYear, handleSelectionChange]
  );

  const setSelectedIndustryInternal = useCallback(
    (option) => handleSelectionChange(option, setSelectedIndustry),
    [setSelectedIndustry, handleSelectionChange]
  );

  useEffect(() => {
    if (shouldFetchFootnotes) {
      fetchFootnotes(); // This will have access to the updated 'query'
      setShouldFetchFootnotes(false);
    }
  }, [shouldFetchFootnotes, fetchFootnotes]);

  let enterOrFiltersClicked =
    selectedIndustry.length > 0 ||
    selectedFootnoteTitles.length > 0 ||
    selectedCompanies.length > 0 ||
    selectedYear.length > 0 ||
    (query && !loading);

  useEffect(() => {
    // Resets the count when no text entered and no filters clicked
    if (!enterOrFiltersClicked) {
      setShouldFetchFootnotes(true);
    }
  }, [enterOrFiltersClicked]);

  const footnoteDataWithFiles = useMemo(() => {
    if (footnotes && footnotes.length > 0) {
      //console.log("footnotes", footnotes);
      const mappedFootnotes = footnotes?.map((footnote) => {
        const pdfFile = footnoteFiles?.find(
          (file) => file.file_id === footnote.pdf_file_id
        );
        const screenshotFile = footnoteFiles?.find(
          (file) => file.file_id === footnote.screenshot_file_id
        );
        return {
          ...footnote,
          pdfFile,
          screenshotFile,
        };
      });

      if (filteredRows === null) {
        return mappedFootnotes;
      } else {
        return mappedFootnotes.filter((f) => filteredRows.includes(f.id));
      }
    } else return [];
  }, [footnotes, footnoteFiles, filteredRows]);

  return (
    <>
      <SignInModal open={signInModalOpen} setOpen={setSignInModalOpen} />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          {/* {!isPublic && (
            <>
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {appInstanceData.custom_name || "Footnotes Visualization"}
                </h1>
              </div>
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
                <Tabs
                  tabs={footnotesTabs}
                  currentTab={activeTab}
                  onClickTab={handleTabChange}
                />
              </div>
              {showFilesTab && (
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
                  {footnoteFiles && footnoteFiles.length > 0 && (
                    <>
                      <h3>Files</h3>
                      <FilesTable
                        files={footnoteFiles}
                        fetchFootnotes={fetchFootnotes}
                        fetchFootnoteFiles={fetchFootnoteFiles}
                      />
                    </>
                  )}

                  <Dialog isOpen={dialogOpen} closeDialog={closeDialog}>
                    <FileUpload onDrop={onDrop} />
                  </Dialog>
                  <br />
                  <button
                    disabled={dialogOpen}
                    onClick={openDialog}
                    className="pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
                  >
                    Upload File
                  </button>
                  {uploading && <p>Uploading...</p>}
                  {parsing && <p>Parsing...</p>}
                </div>
              )}
            </>
          )} */}
          {((!isPublic && showFootnotesTab) || isPublic) && (
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
              <h1 className="text-4xl font-bold text-center text-customHighlightColor mb-4">
                {isPublic ? "Research Footnotes" : "FootNoteDB"}
              </h1>
              <p className="text-center text-gray-600 mb-4 text-xl">
                {isPublic
                  ? " A comprehensive database of footnotes from 10K filings."
                  : " A database of your company's footnotes."}{" "}
              </p>
              {isPublic && (
                <>
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <MessageForm
                      value={query}
                      onChange={({ target: { value } }) => {
                        setQuery(value);
                        setQueryRunning(true); // Set queryRunning to true when typing
                      }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        // Update the query when form is submitted
                        handleSubmitMessageForm(e);
                      }}
                      className="mb-4"
                      // onClear={() => {}}
                      // onStar={() => { }}
                      placeholder="Please describe, in your own words, the type of disclosure you are seeking."
                    />{" "}
                    {loading &&
                      query !== "" &&
                      messagesFromBackend.length > 0 && (
                        <>
                          {/*  <div className="flex flex-col items-center justify-center text-md text-gray-500 my-2 h-64">
                    <div role="status">
                      <span className="flex items-center justify-center">
                        { <svg
                          aria-hidden="true"
                          class="w-8 h-8 items-center justify-center text-customHighlightColor animate-spin dark:text-customHighlightColor fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg> }
                        Creating and executing query
                        <Typed strings={["..."]} loop typeSpeed={40} />
                      </span>
                    </div>
                    <br />
                    We are using a Large Language Model to bring the key words
                    out of your query and then use that information to perform a
                    semantic search over our footnotes database. This narrows
                    down the potentially useful footnotes to tens from hundreds
                    of thousands. We are then asking a Large Language Model, in
                    batches, which of those results are most relevant, narrowing
                    down further. And finally, we ask that same language model,
                    one by one, if a particular footnote is relevant to your
                    query.
                  </div> */}
                          <BlackComputerScreen lines={messagesFromBackend} />
                          <Progress percentage={progressPercentage} />
                        </>
                      )}
                  </div>
                  <div
                    className="flex py-2 align-middle inline-block min-w-full sm:px-6
                lg:px-8"
                  >
                    <div className="relative inline-block w-full text-gray-700">
                      <Select
                        label="Filing Year"
                        options={years?.map(({ year }) => ({
                          value: year,
                          label: year,
                        }))}
                        value={selectedYear}
                        onChange={setSelectedYearInternal}
                        isClearable
                        isMulti
                        placeholder="Filing Year"
                      />
                    </div>
                    <div className="relative inline-block w-full text-gray-700">
                      <Select
                        label="Company"
                        options={companies?.map(({ name }) => ({
                          value: name,
                          label: name,
                        }))}
                        value={selectedCompanies}
                        onChange={setSelectedCompanyInternal}
                        isClearable
                        isMulti
                        placeholder="Company"
                      />
                    </div>
                    <div className="relative inline-block w-full text-gray-700">
                      <Select
                        label="Topic"
                        options={footnoteTitles?.map(({ title }) => ({
                          value: title,
                          label: toTitleCase(title),
                        }))}
                        value={selectedFootnoteTitles}
                        onChange={setSelectedFootnoteTitleInternal}
                        isClearable
                        isMulti
                        placeholder="Topic"
                      />
                    </div>
                    <div className="relative inline-block w-full text-gray-700">
                      <Select
                        label="Industry"
                        options={industries?.map(({ name }) => ({
                          value: name,
                          label: name,
                        }))}
                        value={selectedIndustry}
                        onChange={setSelectedIndustryInternal}
                        isClearable
                        isMulti
                        placeholder="Industry"
                      />
                    </div>
                  </div>
                  {footnoteDataWithFiles &&
                    footnoteDataWithFiles.length > 0 && (
                      <>
                        {/* <h6>
                  {footnotesCount} footnotes{" "}
                  {companiesCount > 0 &&
                    "belonging to " + companiesCount + " companies"}
                </h6>
                <br /> */}
                        <div
                          className="flex py-2 align-middle  text-sm text-gray-600 inline-block min-w-full sm:px-6
                lg:px-8"
                        >
                          {formatNum(footnotesCount)} footnotes.
                        </div>
                        {/* { loading && (
                      <>
                      LOADING!!!!!!
                      </>
                    )} */}
                        {enterOrFiltersClicked &&
                        (!queryRunning || sampleQueryClicked) ? (
                          <>
                            <FootnotesList
                              footnotes={footnoteDataWithFiles}
                              topParagraphs={footnotes.topParagraphs}
                            />
                            <div className="flex justify-between">
                              <div className="flex gap-2 mt-4">
                                {(hasPreviousPage || hasNextPage) && (
                                  <>
                                    <button
                                      onClick={navigatePrevious}
                                      disabled={!hasPreviousPage || loading}
                                      className="pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
                                    >
                                      Previous
                                    </button>
                                    <button
                                      onClick={navigateNext}
                                      disabled={!hasNextPage || loading}
                                      className="pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
                                    >
                                      Next
                                    </button>
                                  </>
                                )}
                              </div>
                              <div className="flex gap-2 items-center">
                                <label htmlFor="pageSize">Page Size</label>
                                <select
                                  type="number"
                                  id="pageSize"
                                  value={pageSize}
                                  onChange={onChangePageSize}
                                  className="pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor bg-white cursor-pointer transition-colors duration-300 focus:border-blue-500 focus:outline-none"
                                >
                                  <option value={5}>5</option>
                                  <option value={10} selected>
                                    10
                                  </option>
                                  <option value={25}>25</option>
                                </select>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="flex h-screen mt-8">
                            {/* Left Column - Trends */}
                            <div className="w-1/2 overflow-y-auto border-r border-gray-300 bg-gray-50 p-4">
                              <h2 className="text-xl font-semibold text-gray-700 mb-4">
                                {/* Trends */}
                                Sample Queries
                              </h2>
                              {samples.map((sample) => (
                                <div
                                  key={sample.id}
                                  className="cursor-pointer p-4 mb-4 bg-white rounded-lg border-2 border-gray-300 transition-all hover:border-purple-700"
                                  onClick={() => {
                                    setQuery(sample.title);
                                    resetToFirstPage();
                                    fetchFootnotes(query);
                                    setSampleQueryClicked(true);
                                  }}
                                >
                                  {/* <span className="text-purple-700 mr-2"> */}
                                  {/* {sample.id} */}
                                  {/* </span> */}
                                  {/* <span className="text-gray-500 mr-2"> */}
                                  {/* • {sample.views} views */}
                                  {/* </span> */}
                                  <span className="text-gray-700 block mt-1 hover:text-purple-700">
                                    {sample.title}
                                  </span>
                                </div>
                              ))}
                            </div>

                            {/* Right Column - Disclosures */}
                            <div className="w-1/2 overflow-y-auto p-6 bg-white rounded-lg shadow-lg">
                              <h2 className="text-xl font-semibold text-gray-700 mb-4">
                                Footnotes By Type
                              </h2>
                              {/* <div className="relative mb-4">
                        <input
                          type="text"
                          placeholder="Search"
                          className="w-full p-2 pl-10 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      </div> */}
                              {footnoteTitles
                                ?.slice(0, 10)
                                .map((disclosure, index) => (
                                  <div
                                    key={index}
                                    className="flex justify-between mb-3 p-2 hover:bg-gray-100 rounded"
                                    onClick={() => {
                                      setSelectedFootnoteTitleInternal([
                                        {
                                          value: disclosure.title,
                                          label: toTitleCase(disclosure.title),
                                        },
                                      ]);
                                    }}
                                  >
                                    <span className="text-gray-700 hover:text-purple-700 cursor-pointer">
                                      {toTitleCase(disclosure.title)}
                                    </span>
                                    <span className="text-gray-500">
                                      {formatNum(disclosure.title_count)}{" "}
                                      results
                                    </span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                </>
              )}
              {!isPublic && (
                <>
                  Coming soon. In the meantime, please upload your files{" "}
                  <Link to={`/app/edit/${appInstanceData.id}`}>
                    here
                  </Link>
                  .
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FootnotesDatabase;
