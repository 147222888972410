import { useCallback, useState } from 'react';

/**
 * Custom hook to manage tab states
 * @param {Object[]} tabs - Array of tab objects containing `id` and `label`.
 * @param {string} defaultTabId - Optional default active tab `id`. Defaults to the first tab.
 * @returns {[string, function]} - Returns the active tab and a function to set the active tab.
 */
const useTabs = (tabs, defaultTabId = null) => {
  const [activeTab, setActiveTab] = useState(defaultTabId || tabs[0]?.id);

  /**
   * Change the active tab
   * @param {Object} tab - Tab object containing `id` and `label`.
   */
  const handleTabChange = useCallback((tab) => {
    if (tabs.some((t) => t.id === tab.id)) {
        setActiveTab(tab.id);
    } else {
        console.error(`Tab with id ${tab.id} does not exist.`);
    }
  }, [tabs]);

  return [activeTab, handleTabChange];
}

export default useTabs;
